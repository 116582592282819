// ADD ALL APPLICATION CONSTANTS HERE
export const TVD = 'TVD';
export const VIEW_MORE = 'View More';
export const VIEW_LESS = 'View Less';
export const WATER = 'Water';
export const ENERGY = 'Energy';
export const WASTE = 'Waste';
export const COST_AND_BENEFITS = 'Cost & Benefit';
export const VIEW_LESS_ICON_BLUE='view_less_blue.png';
export const VIEW_LESS_ICON_WHITE='view_less_white.png';
export const VIEW_MORE_ICON_BLUE='drop-down-blue.png';
export const VIEW_MORE_ICON_WHITE='arrow-down-white.png';
export const KAY_QSR = 'KAY-QSR';
export const QSR = 'QSR';
export const HOW_WAS_THIS_CALCULATED_BLUE = 'assets/images/question-icon-blue.svg';
export const HOW_WAS_THIS_CALCULATED_WHITE = 'assets/images/question-icon-white.svg';
export const ICON_CLOSE_BLACK = 'assets/images/Icon-close_black.svg';
export const ICON_CLOSE_WHITE = 'assets/images/Icon-close_white.svg';
export const CLOSE_ICON = 'assets/images/close_icon.svg';
export const HOME_ICON = 'assets/images/home_icon.svg';
export const HAMBURGERMENU = 'assets/images/hamburger-menu.svg';
export const ICONEDITOR = '/assets/images/icon-editor.svg';


//value active metrics constants
export const CATEOGRYID_MANUAL = 15;
export const DIALOG_MODE_ADD: string = 'add';
export const DIALOG_MODE_MODIFY: string = 'modify';
export const APPLYVALUECHANEGE: string = 'applyClickChanges';
export const VALUE_ACTIVITY_METRIC = 'Value Activity Metric';
export const VALUE_ACTIVITY_METRICS = 'Value Activity Metrics';
export const ADD_VALUE_ACTIVITY_METRIC = 'Add Value Activity Metric';
export const MODIFY = 'Modify';
export const ADD = 'Add';
export const MODIFYIMG = '/assets/images/modify.svg';
export const EDIT = '/assets/images/Edit.png';
export const PLUSIMG = '/assets/images/plus.svg';
export const ADD_NULL = '/assets/images/add_null.svg';
export const ADD_BLUE = 'assets/images/add_blue.svg';
export const BULB = '/assets/images/bulb.svg';
export const DELETE_ICON = '/assets/images/delete_icon.svg';
export const CLOSE = '/assets/images/close.svg';

//Service Insights
export const ARRAOWUP = 'assets/images/uparrow-with-circle.png';
export const SERVICEINSIGHTS = 'Service Insights';
export const ADDINSIGHT = 'Add and Modify Service Insights';
export const ADD_ANOTHER_INSIGHT = '+ Add another insight';
export const INSIGHT = 'Insight';
export const TITLE_INSIGHT = 'Insight Name';
export const DELETE_INSIGHT = 'Delete Insight';
export const COMMITTED = 'committed';
export const LABEL_COMMITTED = 'Committed';
export const VALUE = 'Value';
export const LABEL = 'Label';
export const APPLY = 'Apply';
export const DESCRIPTION = 'Description';
export const COMMITTED_INSIGHT_NAME = 'Committed Service Visit';
export const COMMITTED_DESCRIPTION = 'A target number of service visits defined and agreed upon with the customer (SOW, Contract etc.)';
export const COMPLETED = 'completed';
export const LABEL_COMPLETED = 'Completed';
export const COMPLETED_INSIGHT_NAME = 'Completed Service Visit';
export const COMPLETED_DESCRIPTION = 'The actual number of committed service visits completed, documented, and communicated to the customer.';
export const PRE_INSIGHT_MANAGE = 'Manage Preset Insights';
export const COUSTOM_INSIGHT_MANAGE = 'Manage Manual Insights';
export const NO_CUSTOM_INSIGHTS_ADDED = 'No Manual Insights Added';
export const YOU_CURRENTLY_HAVE_TEXT = 'You currently have no custom insights. Create your first to customize your view.';
export const CLOSE_BLACK = '/assets/images/close_black.svg';

export const OPERATING_TYPE_LITERALS = {
    TITLE: 'Select an operating type',
    LABEL: 'Operating Type',
    FILTER_BY: 'Filter By',
    NO_RESULT:'No Results Found',
};
export const FILTER_TYPES = { CVOC: 'CVOC', EVOC: 'EVOC', SITES: 'site', OPTYPE: 'Operating Type', CUSTOMER_DIVISION: 'Division' };
export const ALL = 'ALL';
//session storage constent
export const USD = 'USD';
export const IMPERIAL = 'Imperial';
export const NO_RESULT_FOUND = 'No Result Found';
