import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./header/header.component";
import { TotalValueComponent } from "./total-value/total-value.component";
import { CardsComponent } from "./cards/cards.component";
import { TilesDataComponent } from "./tiles-data/tiles-data.component";
import { HideShowToggleComponent } from "./hide-show-toggle/hide-show-toggle.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
// import { FooterComponent } from "./footer/footer.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { TranslationService } from "services/translation.service";
import { CurrencyFormattingPipe } from "./pipes/currency-formatting.pipe";
import { UnitFormattingPipe } from "./pipes/unit-formatting.pipe";
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from "@angular/material/dialog";
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
import { OneCustomerUiFooterModule } from "@reusable-lib/one-customer-ui-lib-footer";
import { OneCustomerUiHeaderModule } from '@reusable-lib/one-customer-ui-lib-header';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    HeaderComponent,
    CardsComponent,
    TotalValueComponent,
    TilesDataComponent,
    HideShowToggleComponent,
    // FooterComponent,
    UnauthorizedComponent,
    CurrencyFormattingPipe,
    UnitFormattingPipe,
    ProgressLoaderComponent,
    DialogPopupComponent,
    CurrencySymbolPipe
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    OneCustomerUiHeaderModule,
    TranslateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent,
    CdkTextareaAutosize, TextFieldModule,
    OneCustomerUiFooterModule
  ],
  exports: [
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    CardsComponent,
    TilesDataComponent,
    CardsComponent,
    TotalValueComponent,
    HideShowToggleComponent,
    // FooterComponent,
    UnauthorizedComponent,
    CurrencyFormattingPipe,
    CurrencySymbolPipe,
    UnitFormattingPipe,
    ProgressLoaderComponent,
    MatIconModule,
    OneCustomerUiFooterModule,
    OneCustomerUiHeaderModule
    ],
  providers: [TranslationService]
})
export class SharedModule {}
